const listReset = {
  [`& li:first-of-type`]: {
    paddingTop: 0,
  },
  [`& li:last-of-type`]: {
    paddingBottom: 0,
    marginBottom: `1rem`,
  },
};

export {
    listReset
}