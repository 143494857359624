import { Fragment } from "react";
import { RESOURCE_LABELS, RESOURCE_TYPES } from "../../util/constants"
import Parents from "../components/Resources/Tabs/Parents";
import Staff from "../components/Resources/Tabs/Staff";
import StudentResourceTab from "../components/Resources/Tabs/Student";
import { APPS } from "./appWidgets";
import OHIOIcon from "../../Assets/Images/OHIO";

const unauthSections = [
    {
        tab: 0,
        resourceType: RESOURCE_TYPES.STUDENTS,
        label: RESOURCE_LABELS[RESOURCE_TYPES.STUDENTS],
        id: "students-tab",
        app: APPS.OHIO_STATE_APP,
        Component: StudentResourceTab
    },
    {
        tab: 1,
        resourceType: RESOURCE_TYPES.PARENTS_FAM,
        label: RESOURCE_LABELS[RESOURCE_TYPES.PARENTS_FAM],
        id: "parents-fam-tab",
        app: APPS.OHIO_STATE_APP,
        Component: Parents
    },
    {
        tab: 2,
        resourceType: RESOURCE_TYPES.STAFF_FAC,
        label: RESOURCE_LABELS[RESOURCE_TYPES.STAFF_FAC],
        id: "staff-fac-tab",
        app: APPS.OHIO_STATE_APP,
        Component: Staff
    }
]

const UNAUTH = {
    type: "UNAUTH",
    heading: <Fragment>
        Welcome to Buckeye Link
        <OHIOIcon
            sx={(theme) => {
                const color = theme.palette.brand.gray.main
                
                return {
                    ml: "auto",
                    fontSize: "inherit",
                    fill:color,
                    color
                }
            }}
        />
    </Fragment>,
    sections: unauthSections
}

export {
    UNAUTH
}