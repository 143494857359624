
import { Mui } from "@osu/react-ui";
import ListItem from "./ListItem";
import LoadingItem from "../Loading/ListItem";
import { mergeSxStyles } from "../../../styles/util";
import { listReset } from "../../../styles/commonSx";

const createLink = (article, allowLink) => {
  const { identifier, url: articleUrl } = article ?? {}
  const linkIsAllowed = articleUrl || (article?.link ? !!article.link : allowLink)
  
  let url = undefined 
  if(linkIsAllowed) {
    if(articleUrl) {
      url = articleUrl
    } else {
      const decodedUri = decodeURIComponent(identifier ?? "").split(":")[0];
      if (process.env.REACT_APP_SHARE_ENDPOINT && decodedUri) {
        url = `${process.env.REACT_APP_SHARE_ENDPOINT}&articleId=${encodeURIComponent(decodedUri)}`;
      }
    }
  }
  return url
}

function List(props = {}) {
  const { articles = [], allowLink, sx, ...rest } = props

  return (
      <Mui.List sx={mergeSxStyles(listReset, sx)} {...rest}>
        {articles.map((article = {}, idx) => {
          const { identifier , loading } = article ?? {}
          const lastItem = idx === (articles.length - 1)
          let url = createLink(article, allowLink)
          const uuid = identifier + idx + "list-item"
          if(loading) {
            return <LoadingItem key={uuid} id={uuid} />
          }

          return <ListItem 
            key={uuid}
            {...article}
            className="padding-x-0" 
            divider={articles.length > 1 && !lastItem}
            url={url}
          />
        })}
      </Mui.List>
  );
}

List.defaultProps = {
  articles: []
}

export default List;
