import ArticleLink from "./ArticleLink";
import Body from "./Body";
import Container from "./Container";
import Divider from "./Divider";
import Heading from "./Heading";
import Section from "./Section";

function Switch(component) {
  const { role, text, articleIdentifier, components, variant } = component;

  switch (role) {
    case "section":
      return <Section components={components} />;
    case "body":
      return <Body text={text} />;
    case "container":
      return <Container components={components} />;
    case "heading":
      return <Heading variant={variant} text={text} />;
    case "divider":
      return <Divider />;
    case "article_link":
      return (
        <ArticleLink
          articleIdentifier={articleIdentifier}
          components={components}
        />
      );
    default:
      return false;
  }
}

export default Switch;
