import { Mui, MuiIcons } from "@osu/react-ui";
import A11yIcon from "../../../../Common/components/A11yIcon";
import { classes } from "../../Accordions/Individual";
import { mergeSxStyles } from "../../../../styles/util";
import usePaperStyleProps from "../../../../Common/hooks/usePaperStyleProps";
import SECTION_RESOURCES from "../util/resources";
import { useSelector } from "react-redux";
import { selectAccordionLinks, selectAccordionLinksComparison } from "../../../../services/graphql/slices/buckeye-link";
import { Link } from "react-router-dom";
import { forwardRef } from "react";
import useRoleAuthentication from "../../../../Authentication/hooks/useRoleAuthentication";

const flexing = {
  "& li": {
    flexBasis: "50%",
    cursor: ''
  },
  "& li:nth-child(even)": {
    paddingLeft: 1,
  },
  "& li:nth-child(odd)": {
    paddingRight: 1,
  },
};
const styles = (theme) => ({
  " li": {
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'auto'
  },
  [theme.breakpoints.up("lg")]: flexing,
  [theme.breakpoints.only("sm")]: flexing,
});

const iconStyle = (theme) => ({
  fontSize: "1rem",
  p: 2,
  fontWeight: theme.typography.fontWeightBold,
  verticalAlign: "super",
})

const linkSx = {
  display: 'flex',
  alignItems: 'center', 
  padding: 0
}

const Internal = forwardRef( ({ to, text, Icon, tooltip, ...rest }, ref) => {
  const color = 'primary'
  
  return (
    <Mui.ListItem ref={ref} {...rest}>
      <Mui.Link
        to={to}
        variant={"outlined"}
        component={Link}
        sx={linkSx}
        underline="hover"
      >
        <Mui.ListItemAvatar>
          {Icon && (
            <A11yIcon sx={iconStyle} color={color} >
              <Icon fontSize="small" />
            </A11yIcon>
          )}
        </Mui.ListItemAvatar>
        {text}
      </Mui.Link>
    </Mui.ListItem>
  );
})

const External = forwardRef( ({ text, link, tooltip, ...rest }, ref) => {  
  return (
    <Mui.ListItem ref={ref} {...rest}>
      <Mui.Link underline="hover" href={link} sx={linkSx}>
          <Mui.ListItemAvatar>
              <A11yIcon invert sx={iconStyle}>
                <MuiIcons.Link fontSize="small" />
              </A11yIcon>
          </Mui.ListItemAvatar>
        {text}
      </Mui.Link>
    </Mui.ListItem>
  );
})

function Section({ type }) {
  const { accordionChildren } = usePaperStyleProps();
  const external =
    useSelector((state) => selectAccordionLinks(state, type)  ?? [], selectAccordionLinksComparison);
  const accordionElevation = accordionChildren.elevation;
  const theme = Mui.useTheme();
  const { internal = [] } = SECTION_RESOURCES?.[type] ?? {};
  const { compareUserToRoles } = useRoleAuthentication()
  
  let formattedInternalLinks = [...internal, ...external]
  const anyLinkHasRoles = formattedInternalLinks.find(({ roles }) => !!roles.length)
  if(anyLinkHasRoles) {
    formattedInternalLinks = formattedInternalLinks.filter(link => {
      if(link?.roles?.length) {
        return compareUserToRoles(link.roles)
      }
      return true
    })
  }

  const paperClasses = classes({ color: theme.palette.divider }).accordion;
  if (!external?.length) {
    return false;
  }

  return (
    <Mui.Paper
      elevation={accordionElevation}
      sx={mergeSxStyles(paperClasses, {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      })}
    >
      <Mui.Box
        className={`${Mui.accordionSummaryClasses.root} ${Mui.accordionSummaryClasses.content} ${Mui.accordionSummaryClasses.gutters} padding-2`}
        sx={{
          [`.${Mui.accordionSummaryClasses.content}`]: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
          },
        }}
      >
        <Mui.Typography className="padding-top-2 padding-bottom-1" variant="h3">Quick Links</Mui.Typography>
        <Mui.List
          className="display-flex width-100"
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            ...styles(theme),
          })}
          dense
        >
          {formattedInternalLinks.map(
            ({ snippet, description, title, label, ...remainingProps }, idx) => {
              let text = title ?? label
              const Comp = (remainingProps.to) ? Internal : External
              const tooltipTitle = snippet ?? description
              
              
               return  <Comp key={idx + 'link-section' + encodeURIComponent(title)} text={text} {...remainingProps} tooltip={tooltipTitle} />
            }
          )}
        </Mui.List>
      </Mui.Box>
    </Mui.Paper>
  );
}

export default Section;
