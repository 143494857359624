import { Fragment } from "react";
import { getBuckeyeLinkData, getGoogle, getPerson } from "../../services/data-sources/enums";
import { searchOptions } from "../../services/graphql/constants";
import { selectBuckeyeLinkQuery, selectCategorizedResults } from "../../services/graphql/slices/buckeye-link";
import { RESOURCE_TYPES } from "../../util/constants";
import { alterTitleToId } from "../../util/functions";
import { categories } from "./categories";
import { Mui } from "@osu/react-ui";
import { determinePagination } from "../../services/graphql/buildQueries";

const sortFiltersByAlphabet = (filters = []) => {
  return filters.sort((a, b) => (a?.title > b?.title ? 0 : -1))
}

export const filterBySubcategory = (subcat, items = []) => {
  const highlights = {}
  highlights.id = alterTitleToId(subcat);
  highlights.label = subcat;
  highlights.items = items.filter(({ category, subcategory }) => subcat && [category, subcategory].includes(subcat));

  if (!highlights?.items?.length) {
    highlights.items = items.filter(({ tags = [] }) => subcat && tags?.length && tags.includes(subcat));
  }

  return highlights
}

function createFeaturedLinks(featured = {}, items = []) {
  let featuredResults = {};
  if (featured) {
    Object.values(RESOURCE_TYPES).forEach((type) => {
      if (featured?.[type]?.length) {
        featuredResults[type] = [];
        const subcategories = featured[type];
        subcategories.forEach((subcat) => {
          let highlights = filterBySubcategory(subcat, items)
          if (subcat) {
            if (highlights?.items?.length) {
              highlights.items = highlights.items.slice(0, 3);
              featuredResults[type].push(highlights);
            }
          }
        });
      }
    });
  }

  return featuredResults;
}

function _createCategorizedResultsAndFilters(categories, includeFilters) {
  let categorizedResults = [],
    filters = [];
  categories.forEach((cat) => {
    const id = alterTitleToId(cat);
    categorizedResults.push({
      id,
      title: cat,
      query: getBuckeyeLinkData,
      result: {
        items: [],
      },
    });
    if (includeFilters) {
      filters.push({
        id,
        title: cat,
      });
    }
  });

  const sortedFilters = sortFiltersByAlphabet(filters)
  return {
    categories: categorizedResults,
    filters: sortedFilters,
  };
}

export function createBuckeyeLinkSearchResults(buckeyelink, options = {}) {
  const { includeFilters = false, categories: overrideCategories = [], query } = options;
  const items = buckeyelink?.items;
  let categories = overrideCategories,
    categorizedResults = [],
    filters = [],
    featuredResults = {};

  if (items?.length) {
    if (!categories.length) {
      categories = [...new Set(items.map(({ category }) => category))];
    }

    const { categories: cats = [], filters: fils = [] } = _createCategorizedResultsAndFilters(categories, includeFilters);
    filters = fils;
    categorizedResults = cats;

    let idx = 0
    items.forEach((item = {}) => {
      if (item?.category || item?.subcategory) {
        let correspondingCategory = categorizedResults.find(({ title }) => item.category === title);
        const uuid = (correspondingCategory?.id ?? "-no-category") + "-search-results-" + idx + (query ?? "-no-query")
        if (correspondingCategory?.result?.items) {
          correspondingCategory.result.items.push(item);
        }

        correspondingCategory.uuid = uuid
        correspondingCategory.totalResults = correspondingCategory?.result?.items?.length;
      }
      idx ++
    });
  }

  return {
    items,
    categories,
    categorizedResults,
    featuredResults,
    filters,
  };
}

export const selectResultsAndQuery = (state, active, { loading: isLoading, preview, query }) => {
  const results = selectCategorizedResults(state);
  const blQ = selectBuckeyeLinkQuery(state);
  let bl = [];
  if (active?.length) {
    const filteredResults = results.filter(({ id }) => {
      return active.includes(id);
    });
    bl = filteredResults;
  }
  const allowResults = preview ? query && query === blQ : true;

  if (allowResults) {
    bl = results.filter(({ result }) => {
      return result?.items?.length || isLoading;
    });
  }

  return {
    results: bl,
    query: blQ,
  };
};

export const compareResultsAndQuery = (a, b) => {
  const queryMatches = a?.query === b?.query;

  const getUniqueStamp = (arr = []) => {
    if (!arr?.length) return "";
    let uuid = "";
    for (const item of arr) {
      const { id, result } = item;
      const items = result?.items ?? [];
      uuid += id;
      uuid += items.length;
      const joinedIds = items.map(({ identifier, title }) => {
        let stamp = (identifier ?? "")
        stamp += title ?? ""
        return stamp.substring(0, 2)
      }).join("")
      uuid += joinedIds      
    }
    return uuid;
  };
  const aStamp = getUniqueStamp(a?.results);
  const bStamp = getUniqueStamp(b?.results);

  return queryMatches && aStamp === bStamp;
};

let searchResultOptions = [];
Object.values(searchOptions).forEach((queryType) => {
  const match = categories.find(({ query }) => query === queryType);
  if (match) {
    searchResultOptions.push({
      ...match,
      result: {
        items: [],
      },
    });
  }
});

export const createGenericResults = ({ query, loading, data, options = searchResultOptions, showMoreResults, secondaryLoading }) => {
  if (!query && !secondaryLoading && !loading) {
    return [];
  }
  let searchRes = [],
    max = 3;

  options.forEach(({ query: queryType, result, preview, ...restOfOption }, idx) => {
    const rest = {
      ...restOfOption,
      query: queryType,
      items: [],
    };
    const queries = data?.[queryType]?.queries;
    const showMoreGoogle = queryType === getGoogle && determinePagination(queries)
    const showMoreFindPeople = queryType === getPerson && data?.[queryType]?.nextPageLink
    if(showMoreGoogle || showMoreFindPeople) {
      rest.showMoreResults = showMoreResults
    }
    if (idx >= max) {
      return false;
    }
    let clonedResult = { ...result, query: queryType };
    let isLoading = loading;
    clonedResult.items = [];
    const currentItems = data?.[queryType]?.items ?? [];
    if (currentItems?.length) {
      clonedResult.items = [...currentItems];
    }
    const uuid = queryType + (query ?? "-no-query") + idx
    if (secondaryLoading) {
      [...Array(10)].forEach((empty, idx) => {
        clonedResult.items.push({ isLoading: true, id: `loading-${idx}` + uuid });
      });
    }

    let totalResults = clonedResult?.items?.length;
    if(queryType === getGoogle) totalResults = queries?.nextPage?.[0]?.totalResults
    if(queryType === getPerson) totalResults = data?.[queryType]?.totalItems
    if (totalResults) {
      const showing = clonedResult?.items?.length ?? 0;
      clonedResult.subheading = `Showing ${showing} of ${totalResults} Results`;
    }


    searchRes.push({
      ...rest,
      isLoading,
      result: clonedResult,
      totalResults,
      uuid,
    });
  });

  return searchRes;
};

const SEARCH_LOADING_SKELETONS = {
  title: <Mui.Skeleton variant="text" sx={{ fontSize: "2rem", mr: "60%" }} />,
  snippet: (
    <Fragment>
      <Mui.Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Mui.Skeleton variant="text" sx={{ fontSize: "1rem", mr: "80%" }} />
    </Fragment>
  ),
};
const createSearchItems = ({ isLoading, result, preview }) => {
  let items = [];
  if (result?.isLoading ?? isLoading) {
    items.push(SEARCH_LOADING_SKELETONS);
  } else if (result?.items?.length) {
    items = result.items;
  } else if (preview) {
    items.push({
      title: "No results found.",
    });
  }
  return items;
};

export { searchResultOptions, createFeaturedLinks, createSearchItems, SEARCH_LOADING_SKELETONS };
