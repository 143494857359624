import React, { Fragment } from "react";
import { useWellnessData } from "../../hooks/useWellnessData";
import { Mui } from "@osu/react-ui";
import Heading from "../../../Dashboard/components/Resources/Heading";
import Components from "../ArticleComponents";
import withStyling from "../../hocs/withStyling";

function Crisis({ headingLevelStart }) {
  const { crisis = {}, isLoading } = useWellnessData();
  let title, subtitle  
  if(isLoading) {
    title = <Mui.Skeleton width="50%" />
    subtitle = <Mui.Skeleton />
  } else {
    title = crisis.title ?? ""
    subtitle = crisis.subtitle ?? ""
  }

  return (
    <Fragment>
      <Heading headingLevelStart={headingLevelStart} divider={false}>
        {title ?? "How to Get Help in a Crisis or Emergency"}
      </Heading>
      {subtitle && <Mui.Typography
        className="margin-top-1"
        variant={headingLevelStart ? `h${headingLevelStart + 1}` : "h5"}
      >
        {subtitle}
      </Mui.Typography>}
      <Mui.Divider className="margin-y-2" />
      <Components components={crisis?.components} />
    </Fragment>
  );
}

export default withStyling(Crisis);
