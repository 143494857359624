import { useSelector } from "react-redux";
import { ROLES } from "../../util/constants";
import { selectAuthentication } from "../../services/authorization/slices";
import { useCallback, useMemo } from "react";
import { compareAuthentication } from "../../services/util/selectorEquality";

function useRoleAuthentication(options = {}) {
  const { sections = [] } = options
  const { roles = [], ...rest } = useSelector(selectAuthentication, compareAuthentication);

  const compareUserToRoles = useCallback((allowedRoles = []) => {
    const filteredArray = roles.filter(value => allowedRoles.includes(value));
    
    return process.env.REACT_APP_ACT_AS_STUDENT_ROLE === "true" || !!filteredArray?.length
  }, [roles])

  const filteredSections = useMemo(() => {
    if(!sections?.length) {
      return []
    }
    return sections.filter(({ roles }) => {
      return !roles?.length || compareUserToRoles(roles)
    })
  }, [compareUserToRoles, sections])

  const isStudent = compareUserToRoles([ROLES.STUDENT])
  const isStaffFaculty = compareUserToRoles([ROLES.FACULTY, ROLES.STAFF, ROLES.EMPLOYEE])

  return {
    ...rest,
    roles,
    compareUserToRoles,
    isStudent,
    isStaffFaculty,
    isStaffFacultyNotStudent: !isStudent && isStaffFaculty,
    sections: filteredSections,
  }
}

export default useRoleAuthentication;