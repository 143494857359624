import { createSlice } from "@reduxjs/toolkit";
import { graphqlApi } from "../api";
import { STATUSES } from "../../../util/constants";

const initialState = {
  leave: {
    eligible: false,
    balances: []
  },
  pay: {
    eligible: false,
    net: '-'
  },
  timesheet: {
    eligible: false,
    records: []
  },
  standardHours: 40
};

const hrProfileKey = 'hrProfile'
const hrProfile = createSlice({
  name: hrProfileKey,
  initialState,
  reducers: {
    setBalances(state, action) {
      state.leave.balances = action?.payload ?? []
    },
    setNetPay(state, action) {
      state.pay.net = action?.payload ?? "-"
    },
    setTimesheet(state, action) {
      state.timesheet.records = action?.payload ?? []
    },
    setChips(state, action) {
      state.status = STATUSES.SUCCESS
      state.leave.chips = action?.payload?.leave?.chips ?? []
      state.pay.chips = action?.payload?.pay?.chips ?? []
    },
    setEligibility(state, action) {
      state.pay.eligible = action?.payload?.pay
      state.leave.eligible = action?.payload?.leave
      state.timesheet.eligible = action?.payload?.timesheet
    },
    setStandardHours(state, action) {
      if(action?.payload) {
        state.standardHours = action?.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getHRData.matchPending,
      (state) => {
        state.status = STATUSES.LOADING
      },
      graphqlApi.endpoints.getHRData.matchRejected,
      (state) => {
        state.status = STATUSES.ERROR
      }
    );
  }
});

export const { setChips, setEligibility, setStandardHours, setBalances, setNetPay, setTimesheet } = hrProfile.actions
const reducers = {
  data: hrProfile.reducer,
};

export const selectPayChips = (state = {}) => {  
  return state?.hr?.pay?.chips ?? []
};


export const selectLeaveChips = (state = {}) => {  
  return state?.hr?.leave?.chips ?? []
};


export const selectHrStatus = (state = {}) => {  
  return state?.hr?.status
};

export const selectHRLeaveEligible = (state = {}) => {  
  return state?.hr?.leave?.eligible
};

export const selectHRPayEligible = (state = {}) => {  
  return state?.hr?.pay?.eligible
};

export const selectStandardHours = (state = {}) => {  
  return state?.hr?.standardHours
};

export const selectTimesheetEligible = (state = {}) => {  
  return state?.hr?.timesheet?.eligible
};


export const selectBalances = (state = {}) => {  
  return state?.hr?.leave?.balances ?? []
};

export const selectNetPay = (state = {}) => {  
  let net = state?.hr?.pay?.net
  if(!net) {
    return '-'
  }
  if(net.startsWith('$')) {
    net = net.replace('$', '')
  }
  net = Number(net).toFixed(2)
  const formatted = '$' + net
  
  return formatted
};

export const selectTimesheets = (state = {}) => {  
  return state?.hr?.timesheet?.records ?? []
};

export default reducers;
