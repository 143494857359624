
import { Mui } from "@osu/react-ui";

function LoadingItem(props = {}) {
  const { children, className } = props;
  let cls = `display-flex margin-x-2 margin-y-1`
  if(className) cls += ` ${className}`
  return (
    <li className={cls}>
      <Mui.Skeleton width="5rem"></Mui.Skeleton>
      <Mui.Box sx={(theme) => ({ width: "90%", marginLeft: theme.spacing() })}>
        <Mui.Skeleton width="40%"></Mui.Skeleton>
        <Mui.Skeleton></Mui.Skeleton>
        <Mui.Skeleton></Mui.Skeleton>
        {children}
      </Mui.Box>
    </li>
  );
}

export default LoadingItem;
