import React, { Fragment } from "react";
import { Mui, MuiIcons } from "@osu/react-ui";
import Heading from "../Heading";
import StylizedWrapper from "../StylizedWrapper";
import { COLORS } from "../../../../styles/icon_colors";
import CampusIcon from "../../../../Assets/Images/Campus";

function Parents() {
  const theme = Mui.useTheme()
  const darkMode = theme?.palette?.type === "dark"

  return (
    <Fragment>
      <StylizedWrapper>
        <Heading a11yIconProps={{
          color: COLORS.gray,
          invert: !darkMode
        }} Icon={MuiIcons.EmojiPeople}>Get to Know Ohio State</Heading>
        <Mui.Typography variant="body1">
          There are many resources available online for parents and families of
          current and future Buckeyes. On this screen you will find popular links
          to common tasks, if you are having trouble locating something please use
          the search bar.
        </Mui.Typography>
      </StylizedWrapper>
      <StylizedWrapper>
        <Heading Icon={CampusIcon} a11yIconProps={{
          color: COLORS.gray,
          invert: !darkMode
        }}>Want to Schedule a Campus Tour?</Heading>
        <Mui.Typography variant="body1">
          The Office of{" "}
          <Mui.Link underline="hover" href="https://undergrad.osu.edu">
            Undergraduate Admissions
          </Mui.Link>{" "}
          offers virtual and in-person campus tours year round. Visit{" "}
          <Mui.Link underline="hover" href="https://campusvisit.osu.edu">
            campusvisit.osu.edu
          </Mui.Link>{" "}
          to explore your options and find the type of visit that best fits your
          needs.
        </Mui.Typography>
      </StylizedWrapper>
    </Fragment>
  );
}

export default Parents;
