
import { useWellnessData } from "../../hooks/useWellnessData";
import NewsList from "../../../News/components/List";

function ArticleList() {
  const { featured = {}, isLoading } = useWellnessData();
  return <NewsList 
    articles={featured?.articles ?? []}
    isLoading={isLoading}
  />
}

export default ArticleList
