
import { Mui } from "@osu/react-ui";
import useGridSizing from "../../hooks/useGridSizing";
import usePaperStyleProps from "../../../Common/hooks/usePaperStyleProps";

function StylizedWrapper({ children }) {
  const { gap: GAP } = useGridSizing();
  const { nestedDetail } = usePaperStyleProps()
  return (
    <Mui.Paper
      {...nestedDetail}
      className={`padding-2 margin-bottom-${GAP} display-flex flex-direction-column`}
    >
      {children}
    </Mui.Paper>
  );
}

export default StylizedWrapper;
