import React, { Fragment } from "react";
import Articles from "./Articles";
import Crisis from "./Crisis";
import Resources from "./Resources";
import { useWellnessData } from "../../hooks/useWellnessData";

function Dashboard() {
  const { showResources, showArticles } = useWellnessData()

  return (
    <Fragment>
      <Crisis colorize />
      {showResources && <Resources show={2} />}
      {showArticles && <Articles />}
    </Fragment>
  );
}

export default Dashboard;
