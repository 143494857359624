import { useDispatch } from "react-redux";
import useRoleAuthentication from "../../Authentication/hooks/useRoleAuthentication";
import { useGetAffiliationsQuery, useGetBuckeyeLinkDataQuery } from "../../services/graphql/api";
import { useEffect } from "react";
import { updateBuckeyeLinkData } from "../../services/graphql/slices/buckeye-link";
import { STAFF_FAC, STUDENT } from "../../Dashboard/util/tabSections";
import { UNAUTH } from "../../Dashboard/util/unauthSections";
import { setRoleType } from "../../services/graphql/slices/dashboard";

const AuthenticatedDataSync = () => {
  const { isSuccess: affiliationsSuccess } = useGetAffiliationsQuery();
  const { isSuccess: buckeyeLinkSuccess } = useGetBuckeyeLinkDataQuery();
  const { roles, isStudent, isStaffFaculty } = useRoleAuthentication();
  const dispatch = useDispatch();

  useEffect(() => {
    if (buckeyeLinkSuccess && affiliationsSuccess) {
      dispatch(
        updateBuckeyeLinkData({
          roles,
        })
      );
    }
  }, [affiliationsSuccess, buckeyeLinkSuccess, dispatch, roles]);

  useEffect(() => {
    const { type } = isStudent 
      ? STUDENT
      : isStaffFaculty
      ? STAFF_FAC
      : UNAUTH
  
    if (type && affiliationsSuccess) {
      dispatch(
        setRoleType(type)
      );
    }
  }, [affiliationsSuccess, dispatch, isStaffFaculty, isStudent]);

};

export default AuthenticatedDataSync