import { useParams } from "react-router-dom";
import { useGetWellnessQuery } from "../../services/graphql/api";

const crisisFallback = {
  "authorEmail": "",
  "byline": "",
  "components": [
      {
          "articleIdentifier": null,
          "identifier": "59595e4e-99c8-4c45-a70e-568515424bbf",
          "role": "heading",
          "text": "How to Get Help in a Crisis or Emergency",
          "components": null
      },
      {
          "articleIdentifier": null,
          "identifier": "e93c2fbb-02d6-4ded-a51d-c40cfc819226",
          "role": "section",
          "text": null,
          "components": [
              {
                  "articleIdentifier": null,
                  "identifier": "88523686-0250-47e8-8eb2-49cee2d4abd0",
                  "role": "body",
                  "text": "If you or someone you know is an imminent danger to themselves or someone else, go to the nearest emergency department or call 9-1-1.",
                  "components": null
              },
              {
                  "articleIdentifier": null,
                  "identifier": "a59d7b32-31a0-4152-b684-3c873edca49d",
                  "role": "button",
                  "text": "Dial 9-1-1",
                  "components": null
              }
          ]
      },
      {
          "articleIdentifier": null,
          "identifier": "59adf62d-2ae0-43d7-b5d5-e9f00ba4f1d5",
          "role": "divider",
          "text": null,
          "components": null
      },
      {
          "articleIdentifier": null,
          "identifier": "a516e0bc-1c9c-4123-9500-55c916af0e77",
          "role": "section",
          "text": null,
          "components": [
              {
                  "articleIdentifier": null,
                  "identifier": "63382b40-6bd7-428d-a474-ef10abc80af7",
                  "role": "body",
                  "text": "If you are thinking of harming yourself or if you are worried about someone else and need advice about what to do, call the suicide prevention lifeline at 614-221-5445 or 988 or reach the Crisis Text Line by texting 988.",
                  "components": null
              },
              {
                  "articleIdentifier": null,
                  "identifier": "1e8117dc-9036-469a-b346-502e6f2f24c7",
                  "role": "button",
                  "text": "Call the Suicide Prevention Lifeline",
                  "components": null
              },
              {
                  "articleIdentifier": null,
                  "identifier": "33aab305-9bf2-45c5-b634-3ab534824b85",
                  "role": "button",
                  "text": "Contact the Crisis Text Line",
                  "components": null
              }
          ]
      },
      {
          "articleIdentifier": null,
          "identifier": "90b640df-aad8-4b71-87d7-fc902485bc9b",
          "role": "divider",
          "text": null,
          "components": null
      },
      {
          "articleIdentifier": null,
          "identifier": "dbf3bedb-3747-4ca8-88b9-f4bf12dd270a",
          "role": "section",
          "text": null,
          "components": [
              {
                  "articleIdentifier": null,
                  "identifier": "b9e323b0-12ab-401d-b9c4-b9c54eab3fb9",
                  "role": "body",
                  "text": "If you have experienced sexual assault, relationship violence, or stalking, consider the following:\n\n- Go to a safe place or call 9-1-1.\n- Call someone you trust.\n- Go to an emergency department for a free sexual assault exam.\n\nYou can also contact the 24/7 Helpline at 614-267-7020 or 844-OHIO-HELP.",
                  "components": null
              },
              {
                  "articleIdentifier": null,
                  "identifier": "e7d180b7-7a2a-4c48-9c74-f2bb8e05634b",
                  "role": "button",
                  "text": "Call the 24/7 Sexual Violence Helpline",
                  "components": null
              }
          ]
      },
      {
          "articleIdentifier": null,
          "identifier": "3582896a-30ef-4c18-a0a7-54d52c33623a",
          "role": "divider",
          "text": null,
          "components": null
      },
      {
          "articleIdentifier": null,
          "identifier": "31fbadbe-6fe7-41a4-aa20-2671678d141d",
          "role": "section",
          "text": null,
          "components": [
              {
                  "articleIdentifier": null,
                  "identifier": "856b6d63-ca57-445a-9455-5c6a3d92850f",
                  "role": "photo",
                  "text": null,
                  "components": null
              },
              {
                  "articleIdentifier": null,
                  "identifier": "17174a95-e7de-4dc5-a4fd-9f9be0e36ca1",
                  "role": "body",
                  "text": "Additional crisis resources are available through Student Life [Counseling and Consultation Service](https://ccs.osu.edu/urgent-assistance) and the [Ohio State Suicide Prevention Program](https://suicideprevention.osu.edu/get-help/).",
                  "components": null
              },
              {
                  "articleIdentifier": null,
                  "identifier": "d2ad2103-834e-4e75-8c9f-8c635137e954",
                  "role": "body",
                  "text": "[Learn more about suicide and mental health resources](https://suicideandmentalhealth.osu.edu/get-help) at Ohio State.",
                  "components": null
              }
          ]
      }
  ],
  "identifier": "5b24d6ae-3e71-441c-a663-160ba980cf3e%23mobileApps%3A4abbef6a6f5876c867f9991b5bc4c9c4%23ARTICLE_CONTENT",
  "lastModified": "2023-07-13T16:55:43+00:00",
  "metadata": {
      "excerpt": null,
      "thumbnailAccessibilityCaption": "A decorative image of the Ohio State seal.",
      "thumbnailURL": "https://cms.it.osu.edu/sites/default/files/2023-07/Thumbnail_MarionResource.jpg"
  },
  "webImage": null,
  "subtitle": "Get Help Now.",
  "title": "How to Get Help in a Crisis or Emergency"
}

export function useWellnessData() {
  const { data = {}, isLoading, isError,isSuccess, ...rest } = useGetWellnessQuery();
  const { crisis: crisisData = {}, articles, resources = [] } = data ?? {}
  const params = useParams()
  let crisis = crisisData
  if (!isLoading && !crisisData?.components?.length) {
    crisis = crisisFallback
  }
  
  let resource = null
  if(params?.title && resources?.length) {
    resource = resources.find(({ title: t }) => (t === params.title))
  }
  const showResources = isLoading || !!resources?.length
  const showArticles = isLoading || !!articles?.length

  return {
    ...data,
    ...rest,
    isLoading,
    resource,
    resources,
    crisis,
    showResources,
    showArticles
  };
}
