import React from "react";
import { Mui, MuiIcons } from "@osu/react-ui";
import StylizedWrapper from "./StylizedWrapper";
import Heading from "./Heading";
import { COLORS } from "../../../util/constants";
import { Link } from "react-router-dom";

const LinkOutSection = ({
  Icon,
  heading,
  children,
  href,
  to,
  type,
  color,
  action = {
    label: "",
    Icon: MuiIcons.Image,
    onClick: () => {},
  },
}) => {
  const a11yIconProps = {
    sx: (theme) => {
      const darkMode = theme.palette.mode === "dark"
      const bg = theme.palette.brand.gray[darkMode? "lighter" : "darker"][80]
      return {
        backgroundColor: bg,
        color: theme.palette.getContrastText(bg)
      }
    },
  };

  const { label, Icon: ActionIcon, onClick } = action;

  let actionJsx = false
  if(label && !onClick) {
    let actionProps = {}
    if(href) {
      actionProps.href = href
      actionProps.component = Mui.Link
    } else if (to) {
      actionProps.to = to
      actionProps.component = Link
    }

    actionJsx = (
      <Mui.Button
        className="margin-left-auto"
        variant="outlined"
        color="primary"
        {...actionProps}
      >
        {!!ActionIcon && <ActionIcon className="margin-right-1" />}
        {label}
      </Mui.Button>
    );
  }
  if (onClick && !!label) {
    actionJsx = (
      <Mui.Button
        startIcon={<ActionIcon />}
        sx={{
          whiteSpace: "nowrap",
        }}
        color="primary"
        variant="contained"
        className="margin-left-auto"
        size="large"
        onClick={onClick}
      >
        {label}
      </Mui.Button>
    );
  }

  return (
    <StylizedWrapper>
      <Mui.Box display="flex">
        <Heading
          divider={false}
          color={color ?? COLORS.offwhite}
          Icon={Icon}
          type={type}
          a11yIconProps={a11yIconProps}
        >
          {heading}
        </Heading>
        {actionJsx}
      </Mui.Box>
      <Mui.Divider className="margin-y-2" />
      <Mui.Typography variant="body1">{children}</Mui.Typography>
    </StylizedWrapper>
  );
};

export default LinkOutSection;
