
import A11yIcon from "../../../Common/components/A11yIcon";
import { Mui, MuiIcons, WellnessIcon } from "@osu/react-ui";

function ListItemAvatar(props = {}) {
  const { alt, src, color, webImage, loading, ...rest } = props;

  if(!src && webImage) {
    return <Mui.ListItemAvatar {...rest}>
      <A11yIcon className="padding-1" color={color}>
        <WellnessIcon type={webImage} />
      </A11yIcon>
    </Mui.ListItemAvatar>
  }
  
  if (!src && !loading) {
    return false;
  }
  
  return (
    <Mui.ListItemAvatar {...rest}>
      {
        loading 
          ? <Mui.Avatar>
            <MuiIcons.Image />
          </Mui.Avatar>
          : <Mui.Avatar
            sx={{
              borderRadius: ".25rem",
              height: "3em",
              width: "auto",
              aspectRatio: "1344 / 800",
              marginRight: ".5em"
            }}
            alt={alt}
            src={src}
          />
      }
    </Mui.ListItemAvatar>
  );
}

export default ListItemAvatar;
