
import StylizedWrapper from "../components/Dashboard/StylizedWrapper";

const withStyling = (Component) => {
  return function _withStyling(props = {}) {
    const { colorize, className, ...rest } = props

    return <StylizedWrapper className={className} colorize={colorize}>
        <Component {...rest} />
    </StylizedWrapper>
  };
};

export default withStyling