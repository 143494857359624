import React, { Fragment } from "react";
import List from "../../Common/components/Articles/List";
import Heading from "../../Dashboard/components/Resources/Heading";
import { Mui } from "@osu/react-ui";

function NewsSection({ articles, isLoading, title }) {
  return (
    <Fragment>
      {isLoading && <Mui.Skeleton width="33%" />}
      {title && <Heading>{title}</Heading>}
      <List allowLink disablePadding articles={articles} />
    </Fragment>
  );
}

export default NewsSection;
