
import NewsSection from "./Section";
import { Mui } from "@osu/react-ui"
import useGridSizing from "../../Dashboard/hooks/useGridSizing";

const loading = {
  sectionId: `wellness-loading`,
  articles: [
    { identifier: "wellness-article-0", loading: true },
    { identifier: "wellness-article-1", loading: true },
  ],
};

function NewsList({ isLoading, paper, articles }) {
  const { padding, gap } = useGridSizing()
  if (!articles?.length && isLoading) {
    articles = [loading];
  }

  return articles.map((section, idx) => {
    const { sectionId, title, articles: sectionArticles } = section ?? {};
    const key = idx + "wellness-article" + sectionId
    const newsSectionProps = {
      articles: sectionArticles,
      isLoading,
      title
    }

    if(paper) {
      return <Mui.Paper key={key} className={`padding-${padding} margin-bottom-${gap}`}>
        <NewsSection {...newsSectionProps}/>
      </Mui.Paper>
    }

    return (
      <NewsSection key={key} {...newsSectionProps}/>
    );
  });
}

NewsList.defaultProps = {
  paper: false
}

export default NewsList;
