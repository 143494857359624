import React from "react";
import { MuiIcons } from "@osu/react-ui";
import LinkOutSection from "./LinkOutSection";
import { COLORS } from "../../../styles/icon_colors";

const ExternalApplicationLogin = ({
  Icon,
  extAppName,
  children,
  loginHref,
  color=COLORS.gray
}) => {
  return (
    <LinkOutSection
      Icon={Icon}
      heading={`${extAppName}`}
      href={loginHref}
      color={color}
      action={{
        label: `${extAppName}`,
        Icon: MuiIcons.LockPerson,
      }}
    >
      {children}
    </LinkOutSection>
  );
};

export default ExternalApplicationLogin;
