import { createSlice } from "@reduxjs/toolkit";
import produce, { original } from 'immer'
import { graphqlApi } from "../api";
import { createBuckeyeLinkSearchResults, filterBySubcategory } from "../../../Search/util/functions";
import { RESOURCE_TYPES, STATUSES } from "../../../util/constants";
import { categories } from "../../../Search/util/categories";
import * as JsSearch from 'js-search'
import { selectAuthentication } from "../../authorization/slices";

const initialState = {
  status: STATUSES.IDLE,
  query: "",
  list: [],
  _list: [],
  _categorizedResults: [],
  categorizedResults: [],
  categories: [],
  cache: [],
  featuredResults: {}
};

const searchHelpfulLinks = ({ query = '', documents = [], customIndexes = [] }) => {
  const indexesToSearch = [...customIndexes, 'title', 'tags', 'snippet', 'category', 'subcategory']
  const index = documents?.[0]?.identifier ? "identifier" : "title"
  let search = new JsSearch.Search(index)
  indexesToSearch.map(o => search.addIndex(o))
  search.addDocuments(documents)

  return search.search(query)
}

const resortFilters = (filters = [], searchItems = []) => {
  const categoriesWithResults = searchItems.map(i => i.category)
  const filtersWithoutResults = filters.filter(f => !categoriesWithResults.includes(f.title))
  let filtersWithResults = filters.filter(f => categoriesWithResults.includes(f.title))

  if(filtersWithResults.length) {
    filtersWithResults = filtersWithResults.sort((a,b) => {
      const aIndex = categoriesWithResults.indexOf(a.title)
      const bIndex = categoriesWithResults.indexOf(b.title)
      return (aIndex > bIndex) ? 1 : -1
    })
  }  
  
  return filtersWithResults.concat(filtersWithoutResults)
}

const buckeyeLinkSlice = createSlice({
  name: "buckeyeLink",
  initialState,
  reducers: {
    search(state, action) {
      if(action?.payload?.clear) {
        state.categorizedResults = state._categorizedResults
      }
      const query = action?.payload?.query ?? ""
      
      if(!query || !state._list) {
        if(state._categorizedResults) {
          state.filters = state._filters
          state.categorizedResults = state._categorizedResults
        }
        return
      }

      const match = state.cache.find(({ query: cacheQ }) => {
        return cacheQ === query
      })
      
      if(match?.query && match?.list?.length) {
        state.categorizedResults = match.categorizedResults
        state.query = match.query
        state.list = match.list
        state.filters = match.filters

        return
      }

      const documents = original(state._list)
      const newList = searchHelpfulLinks({
        query,
        documents
      })

      const data = {
        items: newList
      }
      state.list = newList

      const { categorizedResults } = createBuckeyeLinkSearchResults(data, {
        categories: state.categories,
        query
      });

      state.categorizedResults = categorizedResults
      state.query = query
      const filters = resortFilters(state.filters, newList)
      state.filters = filters

      state.cache.push({
        query,
        list: newList,
        categorizedResults,
        filters
      })
    },
    updateReduxStore: (state, { payload }) => {
      state.status = STATUSES.SUCCESS
      const buckeyelink = payload?.data
      let data = {
        featured: buckeyelink?.featured,
        items: buckeyelink?.items
      }
      const options = { includeFilters: true }
      if(state.query) {
        options.query = state.query
      }
      const res =
        createBuckeyeLinkSearchResults(data, {
          includeFilters: true
        });

        const { items, categories, categorizedResults, filters } = res

        state._list = items;
      state._filters = filters
      state.filters = filters
      state.categories = categories;
      state.featuredResults = buckeyelink?.featured ?? {};
      state._categorizedResults = categorizedResults;
      state.categorizedResults = categorizedResults;
    },
    updateBuckeyeLinkData: (state, { payload }) => {
      const { roles: overrideRoles } = payload
      const roles = overrideRoles?.length ? overrideRoles : selectAuthentication(state)?.roles

      return modifyWellnessResources(state, roles)
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      graphqlApi.endpoints.getBuckeyeLinkData.matchPending,
      (state) => {
        return {
          ...state,
          status: STATUSES.LOADING
        }
      },
      graphqlApi.endpoints.getBuckeyeLinkData.matchRejected,
      (state) => ({
        ...state,
        status: STATUSES.ERROR
      }),
    );
  },
});

const modifyWellnessResources = (reduxState, roles) => {
  const countOfWellnessResourcesToRender = 3
  return produce(reduxState, (draftState) => {
    const wellnessState = draftState.featuredResults.wellness ?? []
    const newWellnessItems = wellnessState.map(resource => {
      let wellnessItems = filterBySubcategory(resource.id, draftState._list)?.items ?? []
      let remappedItems = filterLinksWithRoles(wellnessItems, roles)

      remappedItems = remappedItems.slice(0, countOfWellnessResourcesToRender)
      if(remappedItems?.length < countOfWellnessResourcesToRender) {
        const noRoleButRelated = wellnessItems.filter(({ roles }) => !roles?.length)
        remappedItems = remappedItems.concat(noRoleButRelated ?? [])
        remappedItems = remappedItems.slice(0, countOfWellnessResourcesToRender)
      }
      
      return {
        ...resource,
        items:remappedItems
      }
    })

    draftState.featuredResults.wellness = newWellnessItems
  })
}

export const { search, updateReduxStore, updateBuckeyeLinkData } = buckeyeLinkSlice.actions;

export const selectBuckeyeLinkQuery = (state = {}) => {
  return state.buckeyeLink?.query ?? ""
};

export const selectCategories = (state = {}) => {
  return state.buckeyeLink?.categories ?? [];
};

export const selectCategorizedResults = (state = {}) => {
  const filters = state?.buckeyeLink?.filters ?? []
  let results = state.buckeyeLink?.categorizedResults
  
  if(filters?.length) {
    results = results.map(({ ...res }) => {
      const match = filters.findIndex(({ ...o }) => {
        return o.id === res.id
      })
      res.sortPriority = match < 0 ? 0 : match
      return res
    })
    results = results.sort((a, b) => {
      return (a.sortPriority > b.sortPriority) ? 1 : -1
    })
  }
  

  return results
};

export const selectFilters = (state = {}, concat = true) => {
  let fils = state?.buckeyeLink?.filters ?? []
  if(concat) {
   fils = fils.concat(categories)
  }

  return fils
}

const concatItems = (state, field, category) => {
  let list = []
  const featuredLinks = state?.buckeyeLink?.featuredResults?.[field] ?? []
  featuredLinks.forEach(({ items }) => {
    let _items = items
    if(category) {
      _items = _items.filter(({ category: cat }) => cat === category)
    }
    list = list.concat(_items)
  })
  return list
}

const filterLinksWithRoles = (items = [], roles) => {
  const anyLinksWithRoles = items.find(o => o?.roles?.length)
  let filtered = []
  if(anyLinksWithRoles) {
    items.forEach((item) => {
      const { roles: linkRoles = [] } = item
      const matchesToRoles = linkRoles.filter(role => roles?.length && roles.includes(role))
      const countOfRoleMatch = matchesToRoles?.length
      if(matchesToRoles?.length > 0) {
        filtered.push({
          ...item,
          numberOfRolesThatMatch: countOfRoleMatch
        })
      }
    })
    filtered = filtered.sort((a, b) => b.numberOfRolesThatMatch - a.numberOfRolesThatMatch)

    return filtered
  }
  return filtered
}

const filterWorkItems = (state) => {
  const list = (state?.buckeyeLink?._list ?? []).filter(item => item.subcategory === 'Work')
  return list
}

export const selectAccordionLinks = (state = {}, type) => {
  const roles = selectAuthentication(state)?.roles ?? []
  
  let links = []
  if(type === RESOURCE_TYPES.LEARNING) {
    links = concatItems(state, RESOURCE_TYPES.ACADEMIC)
  } else if (type === RESOURCE_TYPES.BUCKID) {
    links = concatItems(state, RESOURCE_TYPES.FINANCIAL)
  } else if (type === RESOURCE_TYPES.HRPROFILE) {
    links = filterWorkItems(state)
  }
  links = filterLinksWithRoles(links, roles)

  let deduped = []
  links.forEach(link => {
    const existsAlready = deduped.find(o => o.link === link.link)
    if(!existsAlready && link.link) {
      deduped.push(link)
    }
  })

  return deduped.slice(0, 6)
}

export const selectAccordionLinksComparison = (a, b) => {
  const mapLinks = (arr) => arr.map(({ link }) => link).join()
  return mapLinks(a) === mapLinks(b)
}

export const selectFeaturedBuckeyeLinkData = (state,resourceType) => {
  const bl = state?.buckeyeLink
  const items = bl?.featuredResults?.[resourceType] ?? []
  
  return items
}

const reducers = {
  getBuckeyeLink: buckeyeLinkSlice.reducer,
};
export default reducers;
