import { Mui } from "@osu/react-ui";

let a = ({ href = "", children }) => {
  return (
    <Mui.Link
      sx={{
        minWidth: "auto",
      }}
      href={href}
      className="margin-0 padding-0 text-transform-none"
    >
      {children}
    </Mui.Link>
  );
};
let p = ({ children, ...rest }) => {
  return <Mui.Typography>{children}</Mui.Typography>;
};
const markdownComponents = {
  a,
  p,
  ul: ({ children }) => (
    <Mui.List
      sx={() => ({
        [`& .${Mui.listItemClasses.root}:before`]: {
          content: '"-"',
          position: "absolute",
          marginLeft: "-12px",
        },
      })}
      dense>
        {children}
    </Mui.List>
  ),
  li: ({ children }) => <Mui.ListItem className="display-block">
    {children}
  </Mui.ListItem>,
};

export default markdownComponents;
