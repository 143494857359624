import React, { Fragment } from "react";
import LogInFullComponent from "../../Shared/LogInFullComponent";
import ExternalApplicationLogin from "../ExternalApplicationLogin";
import { Mui, MuiIcons } from "@osu/react-ui";
import { APPLICATION_NAME } from "../../../../util/constants";

const sisLogin = process.env.REACT_APP_SIS_LOGIN

function Staff() {
  const theme = Mui.useTheme()
  const darkMode = theme?.palette?.mode === "dark"
  const color = theme.palette.brand[darkMode ? 'gray' : 'scarlet'].main

  return (
    <Fragment>
      <LogInFullComponent Icon={MuiIcons.Link} color={color} heading={`Sign in to ` + APPLICATION_NAME} headingSize="4" className="display-flex align-items-center">
        Sign in to view links and information for staff and faculty.
      </LogInFullComponent>
      {sisLogin && < ExternalApplicationLogin extAppName={"Sign in to SIS"}  Icon={MuiIcons.Dvr} loginHref={sisLogin} >
        This is an administrative sign in to the Student Information System(SIS).
      </ExternalApplicationLogin>}
    </Fragment>
  );
}

export default Staff;
