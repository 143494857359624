
import ArticleRouter from "./";

function Container(props = {}) {
    const { components } = props
    if(!components?.length) {
        return false
    }

    return components.map((component = {}) => <ArticleRouter {...component} />)
}

Container.defaultProps = {
    components: []
}

export default Container