import Switch from "./Switch";

function ArticleComponents({ components = [], ...rest }) {
  if (!components?.length) {
    return <Switch {...rest} />
  }

  return components.map((component, idx) => {
    const id = (component?.id ?? component?.identifier ?? component?.articleIdentifier) + idx + "article-component"

    return <Switch key={id} {...component} />
  });
}

export default ArticleComponents;
