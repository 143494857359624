
import { Mui } from "@osu/react-ui";

function ArticleLink({ articleIdentifier, components }) {
  let url = "";
  const title = components.find(({ role }) => role === "article_title")?.text;

  if (!title) {
    return false;
  }

  if (
    !articleIdentifier ||
    articleIdentifier.startsWith("ohiostate-internal:")
  ) {
    return <Mui.Typography>{title}</Mui.Typography>;
  }
  if (process.env.REACT_APP_SHARE_ENDPOINT && articleIdentifier) {
    url += `${process.env.REACT_APP_SHARE_ENDPOINT}&articleId=${articleIdentifier}`;
  }

  return <Mui.Link underline="always" href={url}>{title}</Mui.Link>;
}

export default ArticleLink;
