
import ArticleRouter from "./";

function Section({ components, ...rest }) {
    if(!components?.length) {
        return false
    }

    return <section className="display-flex flex-direction-column">
        {components.map((component = {}, idx) => {
            const id = (component?.id ?? component?.identifier ?? component?.articleIdentifier) + idx + "article-section"

            let variant = undefined
            if(idx === 0 && component?.role === "heading") {
                variant = "h3"
            }
            
            return <ArticleRouter key={id} {...component} variant={variant} />
        })}
    </section>
}

Section.defaultProps = {
    components: []
}

export default Section