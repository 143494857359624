import { Fragment } from "react";
import { Mui, MuiIcons } from "@osu/react-ui";
import { useSelector } from "react-redux";
import { selectStandardHours } from "../../../services/graphql/slices/hrProfileData";

const Bold = (props = {}) => <span className="font-weight-bold" {...props} />;

const standardDaysInWeek = 5;
const standardHours = 8;

const createCustomHourMessage = ({ projected, standardHours, description = '' }) => {
  const weeks = Number(projected / standardHours).toFixed(2)
  if(!standardHours) {
    return false
  }
  return <Fragment>
    {" "}Based on your standard {standardHours} hour work week, you have approximately <Bold>{weeks} weeks</Bold> of {description.toLowerCase()} time available.
  </Fragment>
}

const create40HourMessage = ({  projected, unique }) => {
  const days = Number(projected / standardHours).toFixed(2);
  const isOverAWeek = days > standardDaysInWeek;
  let descriptionJsx = [
    ` Using a typical 40 hour work week, you have approximately `
  ];
  if (!isOverAWeek) {
    descriptionJsx.push(<Fragment key={unique + "is-not-over-week-days"}><Bold> {days} days</Bold>.</Fragment>);
  } else {
    const weeks = days / standardDaysInWeek;
    const weeksRoundedDown = Math.floor(weeks);
    const diff = weeks - weeksRoundedDown;
    const diffInDays = diff * standardDaysInWeek;
    const daysRoundedDown = Math.floor(diffInDays);
    const hours = Number(
      (diffInDays - daysRoundedDown) * standardHours
    ).toFixed(2);
    const pieces = {
      week: weeksRoundedDown,
      day: daysRoundedDown,
      hour: hours,
    };

    let messagePartsToAdd = [];
    const parts = Object.entries(pieces);
    parts.forEach(([type, value], idx) => {
      if (value) {
        let str = "";
        if (idx === 0) {
          str += ` `;
        } else if (idx === parts.length - 1) {
          str += " and ";
        }
        str += `${value} ${type}${value > 1 ? "s" : ""}`;
        messagePartsToAdd.push(str);
      }
    });
    descriptionJsx.push(
      <Bold key={unique + "bold-descr"}>{messagePartsToAdd.join(", ")}.</Bold>
    );
  }
  return descriptionJsx
}

const createMsg = ({ pending, unique, projected, current, description }) => {
  const lcDescription = description.toLowerCase();
  let message = [];
  if (pending) {
    const statement = (
      <Fragment key={unique + "after"}>
        After your pending hours are applied, your <Bold>{lcDescription}</Bold>{" "}
        balance is expected to be <Bold>{projected} hours</Bold>.
      </Fragment>
    );
    message.push(statement);
  } else {
    const statement = (
      <Fragment key={unique + "current"}>
        Your current <Bold>{lcDescription}</Bold> balance is{" "}
        <Bold>{current} hours</Bold>.
      </Fragment>
    );
    message.push(statement);
  }
  return message
}

const InfoBox = ({ current, description, pending, index }) => {
  const unique = index + description;
  let projected = (current ?? 0) - (pending ?? 0);
  projected = projected.toFixed(2);
  const standardHours = useSelector(selectStandardHours)
  let message = createMsg({ pending, unique, projected, current, description })
  let descriptionJsx = (!standardHours || standardHours === 40) 
    ? create40HourMessage({ projected, unique })
    : createCustomHourMessage({ projected, unique, standardHours, description })


  return (
    <Mui.Alert icon={<MuiIcons.Bolt />} role="none" severity="info">
      {message}
      {descriptionJsx}
    </Mui.Alert>
  );
};

export default InfoBox;
