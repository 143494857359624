import { useContext, useState } from "react";
import {
  TableOfContents,
  Mui,
  MuiStyles,
  PageHeading
} from "@osu/react-ui";
import Routes from "../../Routes/components";
import Breadrumb from "./Breadcrumb";
import useContainerWidth from "../hooks/useContainerWidth";
import { useLocation } from "react-router-dom";
import { APPLICATION_NAME } from "../../util/constants";
import useGridSizing from "../../Dashboard/hooks/useGridSizing";
import { PageHeadingContext } from "@osu/react-ui";
import useRoleAuthentication from "../../Authentication/hooks/useRoleAuthentication";
import AuthenticatedDataSync from "./AuthenticatedDataSync";

const createClasses = (theme) => {
  return {
    root: {
      letterSpacing: "",
      [` .${Mui.typographyClasses.root}, *`]: {
        letterSpacing: "normal"
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row-reverse",
      },
      minHeight: "calc(100vh - var(--nav-height) - var(--footer-height))",
      [` .${Mui.linkClasses.root}`]: {
        fontWeight: theme.typography.fontWeightBold
      },
    },
    toc: {
      zIndex: theme.zIndex.appBar - 2,
    },
  };
};


function Main(props = {}) {
  const { unauthorized, loading, ...rest } = props;
  const theme = MuiStyles.useTheme();
  const mdUp = Mui.useMediaQuery((theme) => theme.breakpoints.up("md"));
  const classes = createClasses(theme);
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
  const [hasError, setHasError] = useState(false);
  const pageWidth = useContainerWidth() ?? "xl"
  const location = useLocation()
  const home = !location?.pathname || (location?.pathname === "/")
  const { padding, gap } = useGridSizing()
  const { setHeading, heading } = useContext(PageHeadingContext)
  const { isLoggedIn } = useRoleAuthentication()

  return (
    <Mui.Box flexGrow={100} sx={classes.root} {...rest}>
      {mdUp && (
        <TableOfContents
          spacingTop={false}
          PaperProps={{
            sx: classes.toc,
          }}
          StylizedLinkProps={{ color: "primary" }}
        />
      )}
      {isLoggedIn && <AuthenticatedDataSync />}
      <Mui.Container sx={(theme) => ({ paddingX: theme.spacing(padding) })} disableGutters className={`margin-top-${home ? gap - 1 : 1} margin-bottom-4`} maxWidth={pageWidth}>
        <Breadrumb
          className="margin-y-1"
          links={breadcrumbLinks}
          setBreadcrumbLinks={setBreadcrumbLinks}
        />
        {heading && <PageHeading 
          className={!hasError ? (home ? "sr-only" : "padding-y-2") : undefined}
          applicationName={APPLICATION_NAME} />}
        <Routes setHasError={setHasError} setHeading={setHeading} setBreadcrumbLinks={setBreadcrumbLinks} />
      </Mui.Container>
    </Mui.Box>
  );
}

Main.defaultProps = {
  className: "",
  tabIndex: "-1",
  component: "main",
};

export default Main;
