import React, { Fragment } from "react";
import { Mui } from "@osu/react-ui";

const OSUAppStandard = Mui.createSvgIcon(
  <Fragment>
    <defs>
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="50%"
        id="radialGradient-1"
      >
        <stop stopColor="#F7F7F7" offset="0%"></stop>
        <stop stopColor="#EAEBEB" offset="100%"></stop>
      </radialGradient>
    </defs>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group">
        <rect
          fill="url(#radialGradient-1)"
          x="0"
          y="0"
          width="24"
          height="24"
        ></rect>
        <path
          d="M17.5776923,7.16019231 L14.9200385,4.50253846 L9.10557692,4.50253846 L6.44792308,7.16019231 L6.44792308,16.7405769 L9.10557692,19.3982308 L14.9200385,19.3982308 L17.5776923,16.7405769 L17.5776923,7.16019231 Z M9.17896154,8.34680769 L10.2604615,7.26530769 L13.7651538,7.26530769 L14.8466538,8.34680769 L14.8466538,15.5539615 L13.7651538,16.6354615 L10.2604615,16.6354615 L9.17896154,15.5539615 L9.17896154,8.34680769 L9.17896154,8.34680769 Z"
          id="Fill-25"
          fill="#AF2534"
        ></path>
        <path
          d="M18.4871538,6.77884615 L15.3013846,3.59307692 L8.72423077,3.59307692 L5.53846154,6.77884615 L5.53846154,17.1219231 L8.72423077,20.3076923 L15.3013846,20.3076923 L18.4871538,17.1219231 L18.4871538,6.77884615 Z M5.94553846,6.95065385 L8.89603846,4.00015385 L15.1295769,4.00015385 L18.0799615,6.95065385 L18.0799615,16.9501154 L15.1295769,19.9005 L8.89603846,19.9005 L5.94553846,16.9501154 L5.94553846,6.95065385 L5.94553846,6.95065385 Z"
          id="Fill-27"
          fill="#696B68"
        ></path>
        <path
          d="M14.3448462,8.56523077 L13.5437308,7.76411538 L10.4818846,7.76411538 L9.68076923,8.56523077 L9.68076923,15.3355385 L10.4818846,16.1366538 L13.5437308,16.1366538 L14.3448462,15.3355385 L14.3448462,8.56523077 Z M10.0827692,8.72192308 L10.6314231,8.17326923 L13.3941923,8.17326923 L13.9428462,8.72192308 L13.9428462,15.1788462 L13.3941923,15.7275 L10.6314231,15.7275 L10.0827692,15.1788462 L10.0827692,8.72192308 L10.0827692,8.72192308 Z"
          id="Fill-29"
          fill="#696B68"
        ></path>
      </g>
    </g>
  </Fragment>,
  "ohio state app"
);

const StandardOSUAppIcon = (props) => {
  return <OSUAppStandard {...props} viewBox="0 0 24 24" />;
};

export default StandardOSUAppIcon;