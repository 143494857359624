import { Mui } from "@osu/react-ui";
import Heading from "../Shared/Heading";
import useGridSizing from "../../hooks/useGridSizing";
import useRoleAuthentication from "../../../Authentication/hooks/useRoleAuthentication";
import usePaperStyleProps from "../../../Common/hooks/usePaperStyleProps";

function Name({ sx, className: cls }) {
  const { gap: GAP } = useGridSizing();
  const { accordionChildren, dashboard } = usePaperStyleProps();
  const auth = useRoleAuthentication();
  const firstName = auth?.user?.firstName;
  if (!auth.isLoggedIn) {
    return false;
  }

  let heading = "Hi";
  heading += `, ${firstName ?? "Buckeye"}`;
  heading += "!";

  let className = `display-flex align-items-center padding-y-${GAP} padding-x-2`
  if(cls) className += ` ${cls}`

  return (
    <Mui.Box 
      sx={sx} 
      component={Mui.Paper} 
      elevation={auth.isStudent ? accordionChildren.elevation : dashboard.elevation}
      className={className}>
      <Heading
        elevation={auth.isStudent ? accordionChildren.elevation : dashboard.elevation}
      >
        {heading}
      </Heading>
    </Mui.Box>
  );
}

export default Name;