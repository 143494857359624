import { Fragment } from "react";
import { linkablePathValues, pageTitles } from "../../../../Routes/paths";
import { RESOURCE_LABELS, RESOURCE_TYPES } from "../../../../util/constants";
import { MuiIcons } from "@osu/react-ui"

const SECTION_RESOURCES = {
  [RESOURCE_TYPES.LEARNING]: {
    resourceType: RESOURCE_TYPES.ACADEMIC,
    label: RESOURCE_LABELS[RESOURCE_TYPES.ACADEMIC],
    id: "academic-tab",
    Icon: MuiIcons.School,
    internal: [
      {
        to: linkablePathValues.carmenClass,
        label: pageTitles.carmenClass,
        Icon: MuiIcons.LibraryBooks,
        roles: ["STUDENT"],
        description: `View a list of all of your active Carmen Courses along with a breakdown of your assignments. Click a course title to see all related assignments grouped by timeframe, grades for that course, and various resources to help you succeed.`,
      },
      {
        to: linkablePathValues.finalGrades,
        label: pageTitles.finalGrades,
        roles: ["STUDENT"],
        Icon: () => (
          <Fragment>
            A<sup>+</sup>
          </Fragment>
        ),
        description:
          "View your final term grades. This includes your grade information by class for each term along with both term and cumulative GPA.",
      },
    ],
  },
};

export default SECTION_RESOURCES