import React from "react";
import { MuiIcons } from "@osu/react-ui";
import { useLocation } from "react-router-dom";
import useRoleAuthentication from "../../../Authentication/hooks/useRoleAuthentication";
import LinkOutSection from "../Resources/LinkOutSection";
import useSignIn from "../../../Authentication/hooks/useSignIn";

function LogInFullCompoenent({ headingSize = "2", children, Icon, ...rest }) {
  const { isError, isIdle, isCleared: isUserSignedOut, user } = useRoleAuthentication();
  const { pathname } = useLocation() ?? {};
  const { handleSignIn } = useSignIn()

  const isUserPreSignIn =  isError || isIdle
  const isUserNotPopulated = !Object.keys(user ?? {}).length
  const showAuthButton = ( isUserPreSignIn && isUserNotPopulated ) || isUserSignedOut;
  if (!showAuthButton) {
    return false;
  }

  return (
    <LinkOutSection
      Icon={Icon ?? MuiIcons.School}
      action={{ 
        Icon: MuiIcons.LockPerson,
        label: "Sign In",
        onClick: () => handleSignIn({ redirectPath: pathname }) 
      }}
      {...rest}
    >
      {children ?? "Sign in to view holds, balances, classes, assignments, wellness resources, and much more."}
    </LinkOutSection>
  );
}

export default LogInFullCompoenent;
