import React, { Fragment } from "react";
import withStyling from "../../hocs/withStyling";
import ArticleList from "../Lists/Articles";
import { linkablePathValues } from "../../../Routes/paths";
import { Link } from "react-router-dom";
import { LEARN_MORE } from "../../../util/constants";
import { Mui } from "@osu/react-ui";
import { WELLNESS } from "../../../services/data-sources/enums";

function Articles() {
  return (
    <Fragment>
      <ArticleList />
      <Mui.Divider />
      <Mui.Button
        variant="outlined"
        className="margin-top-2 align-self-end"
        to={linkablePathValues.articles + `/${WELLNESS}`}
        component={Link}
      >
        {LEARN_MORE}
        <Mui.Typography component="span" variant="srOnly"> Wellness Articles</Mui.Typography>
      </Mui.Button>
    </Fragment>
  );
}

export default withStyling(Articles);
