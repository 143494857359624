import { Mui } from "@osu/react-ui";
import DarkOSUAppIcon from "./DarkOSUAppIcon";
import StandardOSUAppIcon from "./StandardOSUApp";

const OSUAppIcon = (props) => {
    const theme = Mui.useTheme();
    const darkMode = theme?.palette?.mode === "dark";
    const Icon = darkMode ? DarkOSUAppIcon : StandardOSUAppIcon;

    return <Icon {...props} />;
  };
  
  export default OSUAppIcon;