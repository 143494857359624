
import { Mui } from "@osu/react-ui";
import ListItemAvatar from "./ListItemAvatar";
import { Link } from "react-router-dom"

const Primary = ({ url, loading, title, to, state }) => {
  if(loading) {
    return <Mui.Skeleton />
  }
  if (!title) {
    return false;
  }
  if (to) {
    return (
      <Mui.Link underline="hover" component={Link} to={to} state={state}>
        {title}
      </Mui.Link>
    );
  }
  if (url) {
    return <Mui.Link underline="hover" href={url}>{title}</Mui.Link>;
  }
  return title;
};

function ListItem(props = {}) {
  const {
    divider,
    metadata,
    title,
    subtitle,
    className,
    url,
    sx,
    webImage,
    color,
    to,
    state,
    loading
  } = props;

  const { thumbnailURL,
    thumbnailAccessibilityCaption,
    excerpt } = metadata ?? {}

  let alt = thumbnailAccessibilityCaption ?? excerpt ?? ""
  if(webImage) alt = title

  return (
    <Mui.ListItem divider={divider} className={className} sx={sx}>
      <ListItemAvatar 
        webImage={webImage}
        alt={alt}
        src={thumbnailURL}
        color={color}
        loading={loading}
      />
      <Mui.ListItemText
        primary={<Primary 
            loading={loading}
            url={url} 
            title={title} 
            to={to} 
            state={state}
            subtitle={subtitle}
        />}
        secondary={loading ? <Mui.Skeleton /> : subtitle}
        secondaryTypographyProps={{
          variant: "body2",
        }}
      />
    </Mui.ListItem>
  );
}

ListItem.defaultProps = {
  link: true
}

export default ListItem;
